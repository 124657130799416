window.jQuery = $;
window.$ = $;

const hljs = require('highlightjs');
hljs.initHighlightingOnLoad();

$(document).ready(function () {
    let input = $("<input type='hidden' name='js_token' \>").val('js_secret');
    $('form#feedback').append(input)
});
